.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.iconContainer {
    margin-bottom: 24px;
}

.iconContainer svg{
    width: 48px;
    height: 48px;
}

.root span {
    text-align: center;
}

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    display: block;
    margin-bottom: 4px;
}

.subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: var(--grey-crumb);
}

@media (max-width: 800px) {
    .root {
        margin-bottom: 40px;
    }

    .title {
        font-size: 14px;
        line-height: 17px;
    }
}
.root {
    display: flex;
    flex-direction: column;
    gap: 32px;
    cursor: pointer;
}

.blogPreviewImage {
    width: 100%;
    object-fit: cover;
    height: 320px;
}

.category {
    text-transform: uppercase;
    color: var(--grey-crumb);
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.link {
    color: var(--standard-black);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-bottom: 1px solid var(--standard-black);
    align-self: start;
    transition: all 0.3s ease-in-out;
}

.link:hover {
    color: var(--standard-gold);
    border-bottom: 1px solid var(--standard-gold);
}

.title {
    font-size: 32px;
    line-height: 38px;
    font-weight: normal;
    flex-grow: 1;
}

.excerpt {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: 120px;
}

.hideReadMoreOnWeb {
    display: none;
}

@media (max-width: 800px) {
    .root {
       margin-bottom: 16px;
    }

    .title {
        font-size: 22px;
        line-height: 26px;
        flex-grow: 0;
    }

    .blogPreviewImage {
        margin-bottom: 16px;
    }

    .excerpt {
        display: none;
    }

    .hideReadMoreOnWeb {
        display: block;
    }
}
.loginFormContainer {
    max-width: 480px;
    margin: 40px auto 128px auto;
}

.loginTitle {
    font-weight: normal;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    margin-bottom: 36px;
}

.subtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    display: block;
}

.loginForm {
    margin-top: 72px;
}

.loginForm > div:first-child {
    margin-bottom: 40px;
}

.loginForm > div:nth-child(2) {
    margin-bottom: 8px;
}

.forgotLink {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    border-bottom: 1px solid var(--standard-black);
    padding-bottom: 1px;
}


.createLink {
    display: block;
    margin: 48px 0px 16px 0px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.forgotLink:hover {
    color: var(--standard-gold);
    border-bottom: 1px solid var(--standard-gold);
}

.errorContainer {
    background-color: var(--error);
    padding: 0px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    max-height: 0px;
    margin-bottom: 16px; /* Space below the error container */
}

.errorMessage {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--standard-white);
    text-align: center;
    display: block;
}

.show {
    visibility: visible;
    opacity: 1;
    max-height: unset;
    padding: 20px 0px;
}

.formButton {
    margin-bottom: 10px;
    margin-top: 25px;
}

.reminder {
    margin-bottom: 10px;
    margin-top: 25px;
}

button.createButton {
    background-color: var(--standard-gold) !important;  /* Blue background */
    color: white;  /* White text color */
    border: none;  /* Remove border */
    padding: 8px 16px;  /* Padding for button */
    cursor: pointer;  /* Pointer cursor on hover */
    border-radius: 4px;  /* Rounded corners */
    margin-bottom: 25px;
    margin-top: 5px;
  }
  
  button.createButton:hover {
    background-color: #86723f !important;  /* Darker blue on hover */
    border: none;
  }

  .link {
    color: blue;
    text-decoration: underline;
    font-size: 14px;
  }
  
  .link:hover {
    color: darkblue; /* Slightly darker blue on hover */
    text-decoration: underline;
  }

  .loginBox {
    max-width: 450px; /* Keeps the form compact */
    margin: 0 auto; /* Centers the box */
    padding: 20px;
    border: 1.5px solid #86723f; /* Thin grey border */
    border-radius: 8px; /* Slightly rounded corners */
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Soft shadow */
    margin-bottom: 25px;
  }
  
@media (max-width: 800px) {
    .root {
        padding: 0px 16px;
    }

    .loginTitle {
        font-size: 32px;
        line-height: 38px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 17px;
    }

    .attributeGridContainer {
        display: none;
    }

    .loginBox {
        max-width: 450px; /* Keeps the form compact */
        margin: 0 auto; /* Centers the box */
        padding: 20px;
        border: 1.5px solid #ffffff; /* Thin grey border */
        border-radius: 8px; /* Slightly rounded corners */
        background-color: #fff;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0); /* Soft shadow */
        margin-bottom: 25px;
      }

    
}

.container {
    box-sizing: border-box;
    max-width: 1280px;
    margin: 0 auto;
    width: 90%;
}

.container.large {
    max-width: 1440px;
}

.container.medium {
    max-width: 900px;
}

.container.min {
    width: 95%;
}

.container.none {
    width: 100%;
}

@media (max-width: 800px) {
    .fullMobile {
        width: 100%;
    }
}


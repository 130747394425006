.root {

}


.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Make sure it covers the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8); /* Optional: Make the background semi-transparent */
    z-index: 9999; /* Ensure it is on top of everything */
  }

/* For the slider container and images */
.slideContainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    height: 550px;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.slideContainer span {
    transition: color 0.3s ease-in-out;
}


.slideContainer img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.content {
    position: absolute;
    bottom: 75px;
    left: 55px;
    color: var(--standard-white);

    z-index: 10;
}
.content a:hover {
    color: var(--standard-gold);
}

.title {
    font-size: 42px;
    line-height: 50px;
    font-family: var(--standard-header);
    margin-bottom: 24px;
    padding-right: 16px;
    display: block;
}

.overlay {
    width: 100%;
    min-height: 630px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: var(--standard-black);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 9;
}

.slideContainer:hover .overlay {
    opacity: 0.4;
    visibility: visible;
}

.collectionContainer {
    margin-top: 8px;
}

.promotionContainer {
    position: relative;
}

.promotionContainer h2 {
    font-size: 52px;
    line-height: 62px;
}


.messageContainer {
    text-align: center;
    padding: 60px 0px;
    margin: 0px 30px;
}

.messageContainer p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}


.gold {
    color: var(--standard-gold);
}

.contentTitle{
    text-align: center;
    font-weight: normal;
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 16px;
}

.newArrivalsContainer {
    margin-top: 64px;
}

.highlightContainer {
    margin: 88px 0px;
}

.linkContainers {
    display: flex;
    position: absolute;
    transform: translate(-50%, 0%);
    bottom: 64px;
    left: 50%;
    gap: 72px;
}

.ctaCustomButton {
    margin-top: 50px;
}

.linkContainers a {
    color: var(--standard-white);
    border-bottom: 1px solid var(--standard-white);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}

.linkContainers a:hover {
    color: var(--standard-gold);
    border-bottom: 1px solid var(--standard-gold);
}

.blogsContainer {
    margin-top: 96px;
}

.sustainableContainer {
    margin: 96px 40px 0px 40px;
}

.socialContainer {
    margin-top: 64px;
}

.socialContentGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.socialContentGrid img {
    width: 100%;
    object-fit: cover;
}

@media (max-width: 800px) {
    .slideContainer {
        height: 290px; /* Adjust height for mobile */
    }

    .content {
        bottom: 30px; /* Adjust spacing for smaller screens */
        left: 20px;
    }

    .title {
        color: white; /* Change to white for better readability */
        font-size: 24px; /* Reduce size for mobile */
        line-height: 30px; 
        font-weight: bold; /* Make it stand out */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for contrast */
    }

    .overlay {
        min-height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Darken overlay to enhance readability */
    }
}

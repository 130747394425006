/* General Container */
.adminFormContainer {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .adminTitle {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }

  .searchInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  /* Form Styling */
  .adminForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .adminForm div {
    display: flex;
    flex-direction: column;
  }
  
  .adminForm label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .adminForm input,
  .adminForm textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .adminForm textarea {
    resize: vertical;
    min-height: 100px;
  }

  .adminForm select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }

  .adminTable td button.editButton {
    background-color: #007bff;  /* Blue background */
    color: white;  /* White text color */
    border: none;  /* Remove border */
    padding: 8px 16px;  /* Padding for button */
    cursor: pointer;  /* Pointer cursor on hover */
    border-radius: 4px;  /* Rounded corners */
  }
  
  .adminTable td button.editButton:hover {
    background-color: #0056b3;  /* Darker blue on hover */
  }

  .paginationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
  }
  
  .paginationButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .paginationButton:hover {
    background-color: #0056b3;
  }
  
  .paginationButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .paginationText {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  
  
  button[type="button"] {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: 100%;
    grid-column: span 2;
  }
  
  button[type="button"]:hover {
    background-color: #45a049;
  }
  
  /* Table Styling */
  .adminTableContainer {
    margin-top: 30px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }
  
  .adminTableContainer h2 {
    font-size: 1.5rem;
    color: #333;
    padding: 15px;
    background-color: #f1f1f1;
    margin: 0;
  }
  
  .adminTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .adminTable th,
  .adminTable td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .adminTable th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  
  .adminTable td img {
    width: 100px;
    height: auto;
  }
  
  .adminTable td button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 15px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .adminTable td button:hover {
    background-color: #d32f2f;
  }
  
  .topLeftButton {
    position: absolute;
    top: 200px; /* Adjust the top margin as needed */
    left: 20px; /* Adjust the left margin as needed */
    z-index: 1000; /* Ensure the button is above other elements */
  }

  .topRightButton {
    position: absolute;
    top: 200px; /* Adjust the top margin as needed */
    left: 20px; /* Adjust the left margin as needed */
    z-index: 1000; /* Ensure the button is above other elements */
  }
  
  
  .modal {
    position: fixed;
    top: 0px;

    width: 100vw;
    height: 100vh;
    z-index: 51;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .adminForm {
      grid-template-columns: 1fr;
    }
  
    .adminTableContainer h2 {
      font-size: 1.25rem;
    }
  
    .adminTable th,
    .adminTable td {
      font-size: 0.875rem;
      padding: 10px;
    }
  
    .adminTable td button {
      font-size: 0.875rem;
      padding: 6px 10px;
    }
  
    button[type="button"] {
      font-size: 1rem;
      padding: 12px 20px;
    }
  }
  
  
.root {
    width: 100%;
    background-color: var(--standard-light-grey);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 50px 0px;
}

@media (max-width: 800px) {
    .root {
        grid-template-columns: repeat(1, 1fr);
    }
}
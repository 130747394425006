.main {
    padding-top: 152px;
    padding-bottom: 150px;
}

.disablePaddingBottom {
    padding-bottom: 0px !important;
}

@media (max-width: 800px) {
    .main {
        padding-top: 100px;
    }
}
.root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.hiddenPrice {
    width: 100%;
    height: 20px;
    background-color: #ffffff;
    border-radius: 4px;
}
.productName {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #333;
}

.productCasa {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #685e03bb;
}

.prices {
    margin-top: 8px;
    font-size: 14px;
    line-height: 19px;
}

.salePrice {
    color: var(--standard-black);
}

.precio_mayor {
    text-decoration: line-through;
    color: var(--grey-placeholder);
}

.meta {
    font-size: 14px;
    line-height: 17px;
    color: var(--grey-placeholder);
}

.prices > span:first-child {
    margin-right: 16px;
}
.hiddenPrice {
    width: 100%;
    height: 20px;
    background-color: #ffffff;
    border-radius: 4px;
}

.salePrice {
    color: var(--standard-black);
}

.precio_mayor {
    text-decoration: line-through;
    color: var(--grey-placeholder);
}

.meta {
    font-size: 14px;
    line-height: 17px;
    color: var(--grey-placeholder);
}

.prices > span:first-child {
    margin-right: 16px;
}
.metaContainer {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 16px;
}

.metaContainer span {
    display: block;
    margin-bottom: 8px;
}

.imageContainer {
    height: 580px;
    width: 100%;
}

.imageContainer img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.actionContainer {
    margin-top: 8px;
    display: flex;
    gap: 16px;
}

.actionContainer span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border-bottom: 1px solid var(--standard-black);
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.actionContainer span:hover {
    border-bottom: 1px solid var(--standard-gold);
    color: var(--standard-gold);
}

@media (max-width: 800px) {
    .imageContainer {
        height: 480px;
    }
}

@media (max-width: 600px) {
    .imageContainer {
        height: 350px;
    }
}

@media (max-width: 400px) {
    .imageContainer {
        height: 220px;
    }
}
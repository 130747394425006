.root {
    margin-top: 16px;
}

.content {
    display: flex;
}

.gallery {
    flex-grow: 1;
    margin-right: 32px;

    position: sticky;
    top: 190px; 
    align-self: start;
}

.details {
    max-width: 450px;
}

.loadingContainer {
    position: fixed;          /* Position it fixed on the screen */
    top: 50%;                 /* Center it vertically */
    left: 50%;                /* Center it horizontally */
    transform: translate(-50%, -50%); /* Adjust positioning for perfect centering */
    z-index: 1000;            /* Make sure it's above other elements */
  }
  

.details h1 {
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 16px;
}

.priceContainer {
    margin: 32px 0px;
}

.priceContainer span {
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
}

.hiddenPrice {
    width: 100%; /* Same width as the price container */
    height: 20px; /* Placeholder height */
    background-color: #ffffff; /* Light gray color */
    border-radius: 4px;
}

.vendor {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.sizeContainer {
    margin-top: 56px;
}

.quantityContainer {
    margin-top: 40px;
}

.quantityContainer span{
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.quantityContainer > div {
    margin-top: 8px;
}

.actionContainer {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
}

.addToButtonContainer {
    flex-grow: 1;
}

.wishlistActionContainer {
    flex-basis: 52px;
    flex-grow: 0;
    flex-shrink: 0;

    color: var(--standard-gold);
    border: 1px solid var(--bg-light-gray);

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;

    cursor: pointer;
    position: relative;
}

.heartFillContainer {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out 0.3s;
}

.heartFillContainer svg {
    width: 18px;
    height: 18px;
}

.description {
    margin-top: 40px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.description p {
    margin-bottom: 24px;
}

.accordionTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.imageContainer {
    width: 900px;
    height: 900px;
    flex-shrink: 0;
    cursor: pointer;
}

.imageContainer img {
    width: 90%;
    height: 100%;
    object-fit: cover;
}



.informationContainer {
    margin-top: 32px;
}

.informationContainer > div:first-child {
    border-top: 1px solid var(--bg-light-gray);
}

.informationContainer > div {
    padding: 24px 0px;
    border-bottom: 1px solid var(--bg-light-gray);
}

.information {
    font-size: 12px;
    line-height: 18px;
}

.suggestionContainer {
    margin-top: 80px;
}

.suggestionContainer h2 {
    text-align: center;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 48px;
}

/* offset layout padding */
.attributeContainer {
    margin-top: 96px;
    margin-bottom: -150px;
}

.show {
    visibility: visible;
    opacity: 1;
}

.hide {
    visibility: hidden;
    opacity: 0;
}



@media (max-width: 800px) {

    .imageContainer {
        width: 100%;  /* Ensure the container takes full width */
        padding: 10px; /* Optional: Adjust padding for mobile */
        height: auto;
        flex-shrink: 0;
        cursor: pointer;
    }

    .imageContainer img {
        max-width: 80%;  /* Limit image width */
        height: auto;    /* Adjust height proportionally */
        object-fit: cover;
    }

    .content {
        display: block;
    }

    .details {
        margin-top: 16px;
        max-width: unset;
    }
    .quantityContainer, .actionContainer {
        margin-top: 16px;    /* Adjust margins to maintain consistency */
    }

    .gallery {    
        position: static;
        margin-right: 0px;
    }

    .suggestionContainer h2 {
        font-size: 22px;
        line-height: 26px;
    }

    .wishlistActionContainer {
        margin-left: 8px;
    }
}
.headerContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.contentContainer {
    width: 100%;
    min-height: 70vh;
    background-color: var(--standard-light-grey);
    padding-top: 32px;
    padding-bottom: 88px;
}

.summaryContainer, .cartContainer {
    margin-top: 72px;
}

.summaryContainer h3 {
    text-align: center;
    font-weight: normal;
    font-size: 52px;
    line-height: 62px;
    position: relative;
    padding-bottom: 10px; /* Space for the line */
  }

.summaryContainer h3::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; /* Thin line */
    background-color: var(--standard-gold); /* Gold color */
  }

.cartContainer {
    display: grid;
    grid-template-columns: 1fr 420px;
}

.cartItemsContainer {
    margin-right: 40px;
}

.cartItemsContainer > div {
    margin-bottom: 40px;
}

.shoppingContainer {
    display: flex;
    align-items: center;
}

.shopLink {
    padding: 10px 20px;
    transition: all ease-in-out 0.3s;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;
    color: var(--standard-black);
}

.shopLink:hover {
    color: var(--standard-gold);
}

.shopLink svg {
    transform: rotate(-180deg);
    margin-right: 8px;
    width: 15px;
    height: 15px;
}

.loginContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.loginContainer a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--standard-black);
    transition: color ease-in-out 0.3s;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.iconButton {
    border: none;
    background-color: unset;
    overflow: visible;
    text-transform: none;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    appearance: button;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
}

.iconContainer svg {
    width: 30px;
    height: 20px;
}

.loginContainer a:hover {
    color: var(--standard-gold);
}

@media (max-width: 1150px) {
    .cartItemsContainer {
        margin-right: 20px;
    }

    .cartContainer {
        grid-template-columns: 1.5fr 1fr;
    }
}

@media (max-width: 900px) {
    .cartContainer {
        grid-template-columns: 1fr;
        margin-top: 40px;
    }

    .summaryContainer h3 {
        font-size: 32px;
        line-height: 38px;
    }

    .continueShopping {
        display: none;
    }

    .headerContainer {
        margin: 0px 8px;
    }
    
}
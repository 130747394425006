.root {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    padding: 100px 0px;
}

.root h1 {
    font-size: 72px;
    line-height: 60px;
    font-weight: normal;
}

.root p {
    font-size: 14px;
    line-height: 20px;
    max-width: 500px;
    margin: 0 auto;
}

.actionContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}

@media (max-width: 800px) {

    .root {
        padding: 50px 0px;
        min-height: unset;
        gap: 16px;
    }

    .root h1 {
        font-size: 32px;
    }

    .actionContainer {
        margin-top: 32px;
        grid-template-columns: 1fr;
        gap: 32px;
    }

}
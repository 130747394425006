.root {
    background-color: var(--bg-dark);
    padding-bottom: 50px;
}

.content {
    padding-top: 72px;
    padding-bottom: 40px;
}

.contentTop {
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.5fr;
}

.newsLetter {
    display: flex;
    justify-content: flex-end;
}

.newsLetterContent {
    max-width: 360px;
}

.newsLetterContent p {
    font-size: 14px;
}

.linkList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.link{
    color: var(--standard-white);
    margin-bottom: 12px;
    display: inline-block;
}

.linkTitle {
    font-size: 18px;
    margin-bottom: 24px;
    display: block;
    color: var(--standard-white);
}

.link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: var(--standard-white);
    transition: width 0.3s ease-in-out;
}

.link:hover::after {
    width: 100%;
}

.promoMessage {
    color: var(--standard-white);
    justify-self: end;
}

.newsLetterForm {
    margin-top: 38px;
    margin-bottom: 80px;
}

.socialContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.socialIconContainer {
    color: var(--standard-white);
    display: flex;
    align-items: center;

    margin-right: 28px;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.socialIconContainer:first-child {
    margin-right: 0px;
}

.socialIconContainer svg {
    width: 40px;
    height: 40px;
}

.contentBottomContainer {
    border-top: 1px solid var(--standard-dark-grey);
}

.contentBottom {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.settings {
    display: flex;
}

.settings > div {
    max-width: 180px;
}

.settings > div:first-child {
    margin-right: 16px;
}

.creditCardContainer {
    display: flex;
    align-items: center;
}

.creditCardContainer > img {
    margin-right: 18px;
}

.creditCardContainer > img:last-child {
    margin-right: 0px;
}

.amexSize {
    width: 21px;
    height: 21px;
}

.masterSize {
    width: 27px;
    height: 17px;
}

.visaSize {
    width: 26px;
    height: 14px;
}

.copyrightContainer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.copyrightContainer span{
    color: var(--standard-white);
    display: block;
    margin-top: 32px;
    font-size: 12px;
    line-height: 15px;
}

.copyrightContainer a {
    color: var(--standard-white);
}

.mobileFooterLinks {
    display: none;
}

.accordionTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--standard-white);
    text-transform: capitalize;
}

.iconContainer {
    color: var(--standard-white);
}

@media (hover: hover) {
    .socialIconContainer:hover {
        background-color: var(--standard-dark-grey)
    }
}

@media (max-width: 800px) {
    .contentTop {
        display: grid;
        grid-template-columns: 1fr;
    }
    
    .newsLetter {
        margin-top: 24px;
        display: flex;
        justify-content: start;
    }

    .newsLetterContent {
        max-width: 100%;
    }

    .socialContainer {
        justify-content: center;
    }

    .contentBottom {
        grid-template-columns: 1fr;
    }

    .copyrightContainer {
        margin-top: 40px;
        align-items: center;
    }

    .newsLetterForm {
        margin-bottom: 30px;
    }

    .settings > div {
        max-width: unset;
    }

    .footerLinks {
        display: none;
    }

    .mobileFooterLinks {
        display: block;
    }

    .footerLinkContainer {
        margin-bottom: 16px;
    }

    .settings {
        display: grid;
        grid-template-columns: 0.55fr 0.45fr;
        gap: 16px;
    }

    .socialIconContainer {
        margin-right: 16px;
    }

    .socialIconContainer:first-child {
        margin-right: 0px;
    }
}

.root {
    margin-top: 16px;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Make sure it covers the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8); /* Optional: Make the background semi-transparent */
    z-index: 9999; /* Ensure it is on top of everything */
  }

.bannerContainer h2 {
    margin-bottom: 24px;
}

.subtitleStyle {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 32px;
}

.metaContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.controllerContainer {
    display: flex;
    gap: 48px;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
}

.iconContainer span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.sortContainer svg {
    width: 8px;
    height: 8px;
}

.itemCount {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.chipsContainer {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.productContainer {
    margin-top: 32px;
}

.loadMoreContainer {
    margin-top: 80px;
    width: 160px;
    margin: 96px auto 0px auto;
    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.loadMoreContainer span {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.mobileItemCount {
    display: none;
}

@media (max-width: 800px) {
    .itemCount {
        display: none;
    }

    .controllerContainer {
        gap: 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .mobileItemCount {
        display: block;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: var(--grey-placeholder);
        margin-bottom: 16px;
    }

    .metaContainer {
        margin: 0px 8px;
    }

    .productContainer {
        margin-left: 8px;
        margin-right: 8px;
    }

    .breadcrumbContainer {
        margin-top: -16px;
    }

    .breadcrumbContainer > div {
        display: none;
    }
}
.root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
  }
  
  .logo {
    height: 85px; /* Adjust based on your design */
    width: auto;
  }
  

@media (max-width: 800px) {

  .logo {
    height: 55px; /* Adjust based on your design */
    width: auto;
  }
}
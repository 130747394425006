.root {
    padding: 32px 0px;
    border-bottom: 2px solid transparent;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
}

.link {
    color: var(--standard-black);
    text-transform: uppercase;
    
    font-weight: 500;
    font-size: 12px;
    line-height: 15px; 
    white-space: nowrap;
}

.root:hover, .active {
    border-bottom: 2px solid var(--standard-black); 
}
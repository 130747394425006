.root {
    position: fixed;
    width: 100%;
    background-color: var(--standard-white);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    z-index: 50;
}
.formField {
    position: relative;
}

.label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 8px;
    position: absolute;
    top: 50%;
    left: 18px;
    transition: all 0.3s ease-in-out;
    transform: translateY(-50%);
}

.label.floating {
    font-size: 12px;
    top: 0;
    transform: translateY(-100%);
    color: var(--primary-color);
}

.input {
    font-family: var(--standard-font);
    padding: 10px 18px;
    border: 1px solid var(--grey-placeholder);
    outline: none;
    transition: border-color 0.3s ease;
}

.input:focus {
    border-color: gold;
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
    top: 0;
    transform: translateY(-100%);
    font-size: 12px;
    color: var(--primary-color);
}

.input.fieldRequired {
    border: 1px solid var(--error) !important;
}

.resultItem {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    transition: background 0.2s ease-in-out;
    justify-content: flex-start; /* Ensure the text appears from the left */
}

.resultItem:hover {
    background: #f9f9f9;
}

.resultImage {
    width: 80px; /* Increased the image size */
    height: 80px;
    object-fit: contain;
    border-radius: 5px;
    margin-right: 15px; /* Added more space between image and text */
}

.resultText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align text from the left */
}

.brand {
    font-size: 12px;
    color: gray;
}

.name {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.tipo_perfume { 
    font-size: 12px;
    color: #333;
}


@media screen and (max-width: 550px) {
    .formField {
        margin-bottom: 15px;
    }
}

.iconButton {
  border: none;
  background-color: unset;
  overflow: visible;
  text-transform: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  appearance: button;
}

.header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    padding: 25px 0px 25px 0px;
    margin: 0 auto;
}

.headerMessageContainer {
    background-color: var(--bg-grey);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerMessageContainer span {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey-crumb);
}

.root .linkContainers {
    display: flex;
}

.navLink {
    margin-right: 40px;
    text-transform: uppercase;
    color: var(--standard-black);
    font-weight: 500;
    padding-bottom: 50px;
    font-size: 12px;
    line-height: 15px;
    border-bottom: 2px solid transparent;
    transition: border 0.3s ease-in-out;
    width: 100%;
}

.activeLink {
    border-bottom: 2px solid var(--standard-black);
}

.actionContainers {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.actionContainers > * {
  margin-right: 32px;
  cursor: pointer;
  color: var(--standard-black);
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
}

.iconContainer svg {
    width: 30px;
    height: 20px;
}

.menuContainer {
    position: absolute;
    background-color: var(--standard-white);
    width: 100%;

    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.show {
    opacity: 1 !important;
    visibility: visible !important;
    max-height: 500px !important;
}

.hide {
    opacity: 0 !important;
    visibility: hidden !important;
    max-height: 0px !important;
    height: 0px;
}

.searchContainer {
    width: 500px;
    margin: 0 auto;
    text-align: center;
    transition: all 0.3s ease-in-out;
    z-index: 51;
}

.searchContainer h4 {
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
}

.searchForm {
    margin-top: 40px;
    margin-bottom: 40px;
}

.searchForm input{
    width: 95% !important;
    border-bottom: 1px solid var(--standard-black) !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
}

.suggestionContianer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    margin-bottom: 40px;
}


.suggestion {
    padding: 14px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--standard-black);
    border: 1px solid var(--bg-light-gray);
    text-transform: capitalize;
    transition: all ;
    cursor: pointer;
}

.suggestion:hover {
    transition: all 0.3s ease-in-out;
    color: var(--standard-white);
    background-color: var(--standard-gold);
    border: 1px solid transparent;
}

.backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: -1;
}

.notificationContainer {
    position: relative;
}

.burgerIcon {
    display: none;
}

.mobileMenuContainer {
    display: none;
}

.bagIconContainer {
    position: relative;
}

.bagNotification {
    position: absolute;
    bottom: -5px;
    right: -5px;

    width: 14px;
    height: 14px;
    background-color: var(--standard-gold);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.bagNotification span{
    color: var(--standard-white);
    font-size: 9px;
}

@media (max-width: 800px) {
    .mobileMenuContainer {
        display: block;
    }

    .linkContainer {
      display: none;
    }

    .burgerIcon {
        display: flex;
        align-items: center;
    }

    .burgerIcon svg {
        width: 20px;
        height: 20px;
    }

    .hideOnMobile {
        display: none;
    }

    .actionContainers > * {
        margin-right: 16px;
        cursor: pointer;
    }

    .actionContainers > *:last-child {
        margin-right: 0px;
    }

    .header {
        padding: 24px 8px 24px 8px;
        margin: 0px 8px;
    }

    .searchContainer {
        width: auto;
        padding: 0px 32px;
    }

    .searchContainer h4 {
        font-size: 32px;
        line-height: 42px;
    }    

    .logo {
        max-height: 60px;  /* Smaller logo on mobile */
    }

    .suggestionsList {
        list-style: none;
        margin: 0;
        padding: 0;
        background: white;
        border: 1px solid #ddd;
        max-height: 200px;
        overflow-y: auto;
      }
      
      .suggestion {
        padding: 10px;
        cursor: pointer;
      }
      
      .suggestion:hover, .suggestion.active {
        background-color: #f0f0f0;
      }
      
      .suggestionText {
        font-weight: normal;
      }
      

}

/* Modal Container */
.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    visibility: hidden; /* Initially hidden */
    transition: visibility 0.3s ease;
}

/* Show modal */
.show {
    visibility: visible;
}

/* Backdrop - The overlay that fades with opacity */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s ease;
}

/* When modal is visible, show the backdrop with opacity */
.show .backdrop {
    opacity: 1;
}

/* Modal Content */
.contentContainer {
    background-color: var(--standard-white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 400px; /* Set default width */
    max-width: 90vw; /* Ensure it’s responsive */
    text-align: center;
    z-index: 1001; /* Ensure it is above the backdrop */
    transform: scale(0.95); /* For animation effect */
    transition: transform 0.3s ease;
}

/* Show the modal content with scaling */
.show .contentContainer {
    transform: scale(1); /* Show in full scale */
}

/* Confirmation Delete Container */
.confirmDeleteContainer h4 {
    color: #d9534f;
    font-size: 20px;
    margin-bottom: 10px;
}

.confirmDeleteContainer p {
    color: #333;
    font-size: 16px;
}

/* Success Message Styling */
.successMessageContainer h4 {
    color: #5cb85c;
    font-size: 20px;
    margin-bottom: 10px;
}

.successMessageContainer p {
    color: #333;
    font-size: 16px;
}

/* Buttons Container */
.actionContainer {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.actionContainer button {
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.3s ease;
}

/* Primary (Delete) Button */
.actionContainer button[level="primary"] {
    background-color: #d9534f;
    color: white;
}

.actionContainer button[level="primary"]:hover {
    background-color: #c9302c;
}

/* Secondary (Cancel) Button */
.actionContainer button[level="secondary"] {
    background-color: #6c757d;
    color: white;
}

.actionContainer button[level="secondary"]:hover {
    background-color: #545b62;
}

/* Responsive for Small Screens */
@media (max-width: 550px) {
    .contentContainer {
        width: 90%;
    }
}

.root {
    width: 100%;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
}

@media (max-width: 800px) {
    .root {
        grid-template-columns: 1fr;
    }
}
.signupFormContainer {
    max-width: 500px;
    margin: 40px auto 128px auto;
}

.title {
    font-weight: normal;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    margin-bottom: 36px;
}

.subtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    display: block;
}

.signupForm {
    margin-top: 72px;
}

.signupForm > div {
    margin-bottom: 24px;
}

.signupForm div:last-of-type {
    margin-bottom: 40px;
}

.reminder {
    display: block;
    margin: 48px 0px 16px 0px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.forgotLink:hover {
    color: var(--standard-gold);
    border-bottom: 1px solid var(--standard-gold);
}

.loginBox {
    max-width: 450px; /* Keeps the form compact */
    margin: 0 auto; /* Centers the box */
    padding: 20px;
    border: 1.5px solid #86723f; /* Thin grey border */
    border-radius: 8px; /* Slightly rounded corners */
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Soft shadow */
    margin-bottom: 25px;
  }

  button.createButton {
    background-color: var(--standard-gold) !important;  /* Blue background */
    color: white;  /* White text color */
    border: none;  /* Remove border */
    padding: 8px 16px;  /* Padding for button */
    cursor: pointer;  /* Pointer cursor on hover */
    border-radius: 4px;  /* Rounded corners */
    margin-bottom: 25px;
    margin-top: 5px;
  }
  
  button.createButton:hover {
    background-color: #86723f !important;  /* Darker blue on hover */
    border: none;
  }

@media (max-width: 800px) {
    .root {
        padding: 0px 16px;
    }

    .title {
        font-size: 32px;
        line-height: 38px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 17px;
    }

    .attributeGridContainer {
        display: none;
    }

    .loginBox {
        max-width: 450px; /* Keeps the form compact */
        margin: 0 auto; /* Centers the box */
        padding: 20px;
        border: 1.5px solid #ffffff; /* Thin grey border */
        border-radius: 8px; /* Slightly rounded corners */
        background-color: #fff;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0); /* Soft shadow */
        margin-bottom: 25px;
      }

}
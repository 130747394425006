.root {

}

.section {
    margin-bottom: 56px;
}

.section h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 32px;
    margin-bottom: 2rem;
}

.section h3 {
    font-weight: normal;
    font-size: 32px;
    line-height: 26px;
    margin-bottom: 1.5rem;
}

.section h4 {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 1.25rem;
}

.section p {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 1rem;
}

@media (max-width: 800px) {

    .section h2 {
        font-size: 28px;
        line-height: 30px;
    }

    .section h3 {
        font-size: 22px;
        line-height: 24px;
    }

    .section h4 {
        font-size: 18px;
        line-height: 22px;
    }

    .section p {
        font-size: 14px;
        line-height: 24px;
    }

}

.root {
}

.section:first-child {
    margin-top: 0px;
}

.section {
    margin-top: 48px;
}

.section h4 {
    font-weight: normal;
    font-size: 32px;
    line-height: 26px;
    margin-bottom: 32px;
}

.section p {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px
}

.contactForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px 24px;
}

.commentInput {
    margin-top: 32px;
    grid-column: 1 / span 2;
}

.customButton {
    width: 220px;
}

.contactContainer {
    margin-top: 32px;
}

@media (max-width: 800px) {
    .contactForm {
        grid-template-columns: 1fr;
    }

    .commentInput {
        grid-column: 1; 
    }

    .customButton {
        width: 100%;
    }

    .commentInput {
        margin-top: 0px;
    }

    .section h4 {
        font-size: 22px;
        line-height: 26px;
    }
}
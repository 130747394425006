.button {
    display: inline-block;
    font-family: var(--standard-font);
    font-size: 14px;
    line-height: 17px;
    padding: 16px 20px 15px;
    margin: 0;
    min-width: 150px;
    height: auto !important;
    transition: all 0.3s ease-in-out;
    text-transform: initial;
    text-align: center;
    outline: none;
    cursor: pointer;
    position: relative;
}

.primary {
    border: 2px solid var(--standard-black);
    color: var(--standard-white);
    background-color: var(--standard-black);;
    text-transform: uppercase;
    font-weight: 500;
    
}

@media (hover: hover) {
    .primary:hover {
        border: 2px solid var(--standard-dark-grey);
        background-color: var(--standard-dark-grey);
    }
}

.secondary {
    border: 2px solid var(--standard-black);
    color: var(--standard-black);
    background-color: var(--standard-white);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.1em;
}

@media (hover: hover) {
    .secondary:hover {
        border: 2px solid var(--grey-border);
    }
}

.secondary.alternate {
    border: 2px solid var(--standard-white);
    color: var(--standard-white);
    background-color: transparent;
}

@media (hover: hover) {
    .secondary.alternate:hover {
        background-color: rgba(255,255,255,0.05);
    }
}

.tertiary {
    border: 1px solid var(--gray-alt);
    color: var(--standard-black);
    background-color: var(--standard-white);
    text-transform: uppercase;
    font-weight: 600;
}

@media (hover: hover) {
    .tertiary:hover {
        border-color: var(--standard-black);
        color: var(--standard-black);
    }
}

.tertiary.alternate {
    border: 1px solid var(--standard-white);
    color: var(--standard-white);
    background-color: transparent;
}

@media (hover: hover) {
    .tertiary.alternate:hover {
        background-color: rgba(255,255,255,0.05);
    }
} 


.small {
    padding: 11px 6px;
    min-width: 135px;
}

.smallest {
    padding: 8px 11px;
    min-width: 92px;
}

.large {
    padding: 22px 0px;
}

.fullWidth {
    width: 100%;
    min-width: 100%;
}

.primary.disabled, .primary[disabled] {
    cursor: default;
    border: 2px solid var(--standard-grey);
    background-color: var(--standard-grey);
    color: var(--grey-passthough);
    pointer-events: none;
}

.secondary.disabled, .secondary[disabled] {
    cursor: default;
    border: 2px solid var(--grey-disabled);
    color: var(--grey-one);
    pointer-events: none;
}

.tertiary.disabled, .tertiary[disabled] {
    cursor: default;
    border: 2px solid var(--gray-alt);
    color: var(--gray-alt);
    background-color: var(--standard-white);
    pointer-events: none;
}



.flat {
    border-radius: 0px;
}

.link {
    padding: 0px;
    text-decoration: underline;
    min-width: auto;
    font-weight: normal;
}

.link:hover {
    text-decoration: none;
}

.root {
    width: 100%;
}

.detailsContainer {
    margin-top: 16px;
}

.imageContainer {
    position: relative;
    cursor: pointer;
}

.root img {
    display: block;
    width: 100%;
    height: 200px; /* Fixed height for consistent size */
    object-fit: contain; /* Ensures the image is not cropped */
    border-radius: 1px;
}

.productName {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #333;
}

.productCasa {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #685e03bb;
}

.prices {
    margin-top: 8px;
    font-size: 14px;
    line-height: 19px;
}

.prices span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
}

.hiddenPrice {
    width: 100%;
    height: 20px;
    background-color: #ffffff;
    border-radius: 4px;
}

.salePrice {
    color: var(--standard-black);
}

.precio_mayor {
    text-decoration: line-through;
    color: var(--grey-placeholder);
}

.meta {
    font-size: 14px;
    line-height: 17px;
    color: var(--grey-placeholder);
}

.prices > span:first-child {
    margin-right: 16px;
}

.bagContainer {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 70px;
    height: 70px;
    background-color: var(--standard-white);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.imageContainer:hover .bagContainer {
    opacity: 1;
    visibility: visible;
}

.heartContainer {
    position: absolute;
    top: 32px;
    right: 32px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
}

.root:hover .heartContainer {
    opacity: 1;
    visibility: visible;
}

.heartFillContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    color: var(--standard-gold);
    transition: opacity 0.3s ease-in-out;
}

.heartFillContainer svg {
    width: 22px;
    height: 22px;
}

.show {
    visibility: visible;
    opacity: 1;
}

.hide {
    visibility: hidden;
    opacity: 0;
}

@media (max-width: 800px) {
    .bagContainer {
        opacity: 2;
        visibility: visible;
        width: 50px;
        height: 50px;
        bottom: -10px;
    }

    .heartContainer {
        opacity: 1;
        visibility: visible;
    }
}

@media (max-width: 600px) {
    .root img {
        height: 250px !important; /* Adjusting for smaller screens */
    }

    .productName {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
    }

    .prices span {
        font-size: 13px;
        line-height: 16px;
    }
}

@media (max-width: 400px) {
    .root img {
        height: 200px !important; /* Adjusting for even smaller screens */
    }

    .bagContainer {
        width: 40px;
        height: 40px;
    }

    .bagContainer svg {
        width: 20px;
        height: 20px;
    }

    .heartContainer {
        top: 32px;
        right: 16px;
    }
}

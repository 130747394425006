@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

.list {
  margin: 20px auto;
}

.list ul {
  padding: 0;
  margin: 0;
}

.list ul li {
  list-style: none;
  margin: 5px 0;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 0 15px; /* Adjust padding */
  border-radius: 8px;
  max-width: 100%;
  transition: width 0.5s ease-in-out;
  position: relative;
  width: var(--bar-width, 50%);
  
  /* Static height */
  height: 30px; /* Adjust this value to fit your design */
  line-height: 30px; /* Ensures text stays vertically centered */
}

/* Border for text contrast */
.list ul li::after {
  content: attr(data-label);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

/* Animation */
.list ul li.animate {
  animation: growBar 0.6s ease-in-out forwards;
}

/* Progressive width animation */
@keyframes growBar {
  from {
    width: 0%;
  }
  to {
    width: var(--bar-width);
  }
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .list {
    max-width: 100%;
    padding: 0 5px;
  }
  
  .list ul li {
    font-size: 0.9rem;
    padding: 0 10px;
    height: 25px; /* Slightly smaller height for mobile */
    line-height: 25px;
  }
  
  .list ul li::after {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .list ul li {
    font-size: 0.8rem;
    padding: 0 8px;
    height: 22px;
    line-height: 22px;
  }
  
  .list ul li::after {
    font-size: 0.75rem;
  }
}

.formField {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #777;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
  }

.label.floating {
    font-size: 12px;
    top: 0;
    transform: translateY(-100%);
    color: var(--primary-color); /* Optional, can change the color when the label floats */
}

.input {
    font-family: var(--standard-font);
    letter-spacing: 0.4px;
    padding: 10px 18px;
    border: 1px solid var(--grey-placeholder); /* Default border color */
    outline: none;
    transition: border-color 0.3s ease; /* Smooth transition for border color */
}

.input:focus {
    border-color: gold; /* Change the border color to gold when focused */
}

.input:focus + .label,
.input:not(:placeholder-shown) + .label {
    top: 6px;
    transform: translateY(-100%);
    font-size: 12px;
    color: var(--primary-color); /* Optional, can change label color on focus */
}

.input.fieldRequired {
    border: 1px solid var(--error) !important;
}

.textarea.fieldRequired {
    border: 1px solid var(--error) !important;
}

.note {
    font-size: 0.75rem;
    color: var(--grey-close-icon);
}

.iconContainer {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: 18px;
    font-size: 24px;
}

.offsetIcon {
    top: calc(50% + 16px);
}

.conditionalIconPadding {
    padding-right: 38px !important;
}

@media screen and (max-width: 550px) {
    .formField {
        margin-bottom: 15px;
    }
}
